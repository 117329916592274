import themeConfig from "@configs/themeConfig";
import { createContext, useReducer } from "react";

const ConfigContext = createContext();
export default ConfigContext;
export const ConfigProvider = ({ children }) => {

    const initialNavbar = {
        query: "",
        bookmarks: [],
        suggestions: []
    }
    const initialLayout = {
        skin: themeConfig.layout.skin,
        isRTL: themeConfig.layout.isRTL,
        layout: themeConfig.layout.type,
        lastLayout: themeConfig.layout.type,
        menuCollapsed: false,
        footerType: themeConfig.layout.footer.type,
        navbarType: themeConfig.layout.navbar.type,
        menuHidden: themeConfig.layout.menu.isHidden,
        contentWidth: themeConfig.layout.contentWidth,
        navbarColor: themeConfig.layout.navbar.backgroundColor
    }
    const initialLayoutKeys = Object.keys(initialLayout)


    function navbarReducer(state, action) {
        return state
    }


    function layoutReducer(state, action) {
        let type = action.type.split('_')
        let newState = state
        initialLayoutKeys.forEach(key => {
            if (key === type[1]) {
                newState = { ...newState, [key]: action.payload }
            }
        })

        return newState

    }

    const [navbar, dispatchNavbar] = useReducer(navbarReducer, initialNavbar)
    const [layout, dispatchLayout] = useReducer(layoutReducer, initialLayout)


    return (
        <ConfigContext.Provider value={{ navbar, layout, dispatchLayout }}>
            {children}
        </ConfigContext.Provider>
    )
};
